/// <reference types="vite-plugin-svgr/client" />

import "./App.scss";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { WagmiProvider } from "wagmi";

import ToolsBar from "./components/ToolsBar/ToolsBar";
import MintNFT from "./pages/MintNFT/MintNFT";
import { wagmiConfig } from "./utils/wagmi";

function App() {
  const queryClient = new QueryClient();
  return (
    <div className="App">
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <header>
              {/* <Navbar navItems={navItems} /> */}

              <ToolsBar />
            </header>
            <section className="mainSection">
              <Routes>
                <>
                  <Route path="/" element={<MintNFT />} />
                </>
              </Routes>
            </section>
          </BrowserRouter>
          <Toaster position="bottom-right" reverseOrder={false} />
        </QueryClientProvider>
      </WagmiProvider>
    </div>
  );
}

export default App;
