import { defineChain } from "viem";

export const JUNE = defineChain({
  id: 45003,
  chainId: 45003,
  name: "JUNE-Chain",
  network: "JUNE-Chain",
  nativeCurrency: {
    decimals: 18,
    name: "JUNE-Chain",
    symbol: "JUNE",
  },
  rpcUrls: {
    public: { http: ["https://rpc.juneo-mainnet.network/ext/bc/JUNE/rpc"] },
    default: {
      http: ["https://rpc.juneo-mainnet.network/ext/bc/JUNE/rpc"],
    },
  },
  blockExplorers: {
    default: {
      name: "Juneo Scan",
      url: "https://juneoscan.io/chain/2",
    },
  },
  contracts: {
    nft: {
      address: "0x71AF42Ba09953D55dD537012aDB683c6f99bb7A3",
    },
  },
});

export const socotraJUNE = defineChain({
  id: 101003,
  chainId: 101003,
  name: "Socotra JUNE-Chain",
  network: "Socotra JUNE-Chain",
  nativeCurrency: {
    decimals: 18,
    name: "Socotra JUNE-Chain",
    symbol: "JUNE",
  },
  rpcUrls: {
    public: { http: ["https://rpc.socotra-testnet.network/ext/bc/JUNE/rpc"] },
    default: {
      http: ["https://rpc.socotra-testnet.network/ext/bc/JUNE/rpc"],
    },
  },
  blockExplorers: {
    default: {
      name: "Juneo Scan",
      url: "https://socotra.juneoscan.io/chain/2",
    },
  },
  contracts: {
    nft: {
      address: "0x923C30e5F54873127734F51E39188f1214C5bE58",
    },
  },
  testnet: true,
});
