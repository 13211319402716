import "./History.scss";

import { useEffect, useState } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Abi, Address } from "viem";
import { useAccount } from "wagmi";

import contractAbi from "../../../abi/erc721.json";
import { JUNE } from "../../../config/chainsConfig";
import { useContractTransaction } from "../../../hooks/useContractTransaction";
import { Button } from "../../../mcn-react-ui";
import DynamicTable, {
  Column,
} from "../../../mcn-react-ui/DynamicTable/DynamicTable";

const History = () => {
  const { executeReadContract } = useContractTransaction();
  const { address, chain, isConnected } = useAccount();
  const [mintTransactions, setMintTransactions] = useState<number[]>([]);

  if (!address || !isConnected) return <span>Please connect your wallet</span>;
  const chainExplorerUrl =
    chain?.blockExplorers?.default?.url ?? JUNE.blockExplorers.default.url;

  const nftContractAddress =
    typeof chain?.contracts?.nft === "object" &&
    "address" in chain.contracts.nft
      ? chain.contracts.nft.address
      : undefined;

  if (!nftContractAddress) {
    console.error("No NFT contract address available.");
    return;
  }

  useEffect(() => {
    const fetchMintTransactions = async () => {
      if (!address || !isConnected) return;

      try {
        // Récupération des IDs des tokens mintés
        const result = await executeReadContract({
          address: nftContractAddress as Address,
          abi: contractAbi as Abi,
          functionName: "getMintsByUser",
          args: [address],
        });

        if (result) {
          setMintTransactions(result as number[]);
        }
      } catch (error) {
        console.error("Error fetching mint transactions:", error);
      }
    };

    fetchMintTransactions();
  }, [address, executeReadContract]);

  // Configuration des colonnes pour le tableau dynamique
  const columns: Column[] = [
    {
      title: "Token ID",
      dataIndex: "tokenId",
      render: (value) => (
        <Button
          onClick={() => {
            // Redirige vers l'URL dans un nouvel onglet
            const url = `${chainExplorerUrl}/token/${nftContractAddress}/${value}`;
            window.open(url, "_blank", "noopener,noreferrer");
          }}
          style="transparent"
        >
          Explorer Transaction
          <FaExternalLinkAlt />
        </Button>
      ),
    },

    {
      title: "Download",
      dataIndex: "download",
      render: () => (
        <Button
          onClick={() => {
            const pdfUrl =
              "https://ipfs.io/ipfs/QmaNfkgKiYjZkoGeiB4pUfeiiQ1y5yhig8o7W3LFDXaAKE";
            const link = document.createElement("a");
            link.href = pdfUrl;
            link.target = "_blank"; // Ouvre dans un nouvel onglet
            link.download = "MintedNFT.pdf"; // Nom du fichier lors du téléchargement
            link.click();
          }}
          label="Download PDF"
        />
      ),
    },
  ];

  // Préparation des données pour DynamicTable
  const tableData = mintTransactions.map((tokenId) => ({
    tokenId,
  }));

  console.log("tableData", tableData);

  return (
    <div className="history">
      <h1>History of Minted NFTs</h1>
      {isConnected ? (
        <DynamicTable data={tableData} columns={columns} pagined maxRow={10} />
      ) : (
        <span>Please connect your wallet</span>
      )}
    </div>
  );
};

export default History;
