import "./WalletConnectButton.scss";

import { useWeb3Modal } from "@web3modal/wagmi/react";
import Blockies from "react-blockies";
import { useAccount, useBalance } from "wagmi";

import { Button, FormatAddress } from "../../../mcn-react-ui";

const WalletConnectButton = () => {
  const { open } = useWeb3Modal();
  const { isConnected, address } = useAccount();

  const result = useBalance({
    address: address,
  });

  console.log("balance", result);

  if (isConnected && address) {
    return (
      <Button className="connectedWallet" onClick={open} style="transparent">
        {result && (
          <span>
            Credit Units : {Number(result.data?.formatted).toFixed(2)}
          </span>
        )}
        <Blockies seed={address} size={8} scale={2.5} />
        <FormatAddress hex={address} start={6} end={6} />
      </Button>
    );
  }
  return (
    <Button
      onClick={open}
      label="Connect Wallet"
      className="walletConnectButton"
    />
  );
};

export default WalletConnectButton;
