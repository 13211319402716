import "./NFTList.scss";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Abi, Address, parseEther } from "viem";
import { useAccount } from "wagmi";

import contractAbi from "../../../abi/erc721.json";
import NotaryThumb from "../../../assets/img/notary-thumb.jpg";
import Notary from "../../../assets/img/notary.png";
import { useContractTransaction } from "../../../hooks/useContractTransaction";
import { Button } from "../../../mcn-react-ui";
import useLoadingStore from "../../../stores/LoadingStore";

type NFT = {
  id: string;
  metadataURI: string;
  name?: string;
  image?: string;
  description?: string;
  pdf?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  document_details?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parties?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  property_details?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transaction?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  taxes_and_fees?: any;
};
const nft: NFT = {
  id: "1",
  metadataURI:
    "https://ipfs.io/ipfs/QmU4BBEXcuFM5xDjpzUPYZ55drviGXbeEgfCks9RcQXqXx",
  pdf: "https://ipfs.io/ipfs/QmaNfkgKiYjZkoGeiB4pUfeiiQ1y5yhig8o7W3LFDXaAKE",
};

interface NFTListProps {
  setActiveButton: (button: string) => void;
}
const NFTList = ({ setActiveButton }: NFTListProps) => {
  const [nftData, setNftData] = useState<NFT | null>(null);
  const { executeTransaction, executeReadContract } = useContractTransaction();
  const { loadingStates } = useLoadingStore();
  const { isConnected, chain, address } = useAccount();

  const [ownNft, setOwnNft] = useState(0n);

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const response = await fetch(nft.metadataURI);
        if (!response.ok) throw new Error("Failed to fetch metadata");

        const metadata = await response.json();
        setNftData(metadata);
      } catch (error) {
        console.error("Error fetching metadata:", error);
      }
    };

    fetchMetadata();
  }, []);

  useEffect(() => {
    const checkOwnership = async () => {
      if (!isConnected) return;

      console.log("Checking ownership of NFT");

      const nftContractAddress =
        typeof chain?.contracts?.nft === "object" &&
        "address" in chain.contracts.nft
          ? chain.contracts.nft.address
          : undefined;

      if (!nftContractAddress) {
        console.error("No NFT contract address available.");
        return;
      }

      try {
        const result = (await executeReadContract({
          address: nftContractAddress as Address,
          abi: contractAbi as Abi,
          functionName: "balanceOf",
          args: [address as Address],
        })) as bigint;

        if (result) {
          console.log("result", result);
          if (result > 0n) {
            setOwnNft(result);
          }
        }
      } catch (error) {
        console.error("Error checking ownership:", error);
      }
    };

    checkOwnership();
  }, [address, isConnected]);

  const handleMint = async (uri: string) => {
    if (!isConnected) {
      console.error("User is not connected.");
      return;
    }

    const nftContractAddress =
      typeof chain?.contracts?.nft === "object" &&
      "address" in chain.contracts.nft
        ? chain.contracts.nft.address
        : undefined;

    if (!nftContractAddress) {
      console.error("No NFT contract address available.");
      return;
    }

    try {
      const hash = await executeTransaction({
        address: nftContractAddress as Address,
        abi: contractAbi as Abi,
        functionName: "mintNFTs",
        args: [uri],
        value: parseEther("0.01"),
      });

      if (hash) {
        setActiveButton("History");
        toast.success("Transaction sent");
      }
    } catch (error) {
      console.error("Error executing transaction:", error);
      toast.error("Transaction failed");
    }
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const capitalizeWords = (string: string) => {
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderDetails = (title: string, details: any) => (
    <div className="detail-section">
      <h4>{title}</h4>
      {Object.entries(details).map(([key, value]) => (
        <div key={key} className="detail-item">
          <strong>{capitalizeWords(key.replace(/_/g, " "))}:</strong>{" "}
          {typeof value === "object" &&
          value !== null &&
          !Array.isArray(value) ? (
            <div className="nested-details">{renderDetails("", value)}</div>
          ) : Array.isArray(value) ? (
            value.map((item, index) => (
              <div key={index} className="list-item">
                {renderDetails("", item)}
              </div>
            ))
          ) : (
            <p>{isConnected ? capitalizeFirstLetter(String(value)) : ""}</p>
          )}
        </div>
      ))}
    </div>
  );

  return (
    <div className="nftContainer">
      <div className="pdfViewer">
        <div className="headerprev">
          <img src={Notary} alt="Notary" className="notaryLogo" />
          <span> Current Contract Balance : {Number(ownNft)}</span>
        </div>
        {ownNft === 0n ? (
          <img src={NotaryThumb} alt="Notary" className="notaryThumb" />
        ) : nft.pdf ? (
          <iframe
            src={nft.pdf}
            title="PDF Viewer"
            width="100%"
            height="600px"
            style={{ border: "none" }}
          />
        ) : (
          <p>No PDF available</p>
        )}
      </div>
      <div className="info">
        <div className="mintButton">
          <Button
            onClick={() => handleMint(nft.metadataURI)}
            label="Mint Contract"
            isLoading={loadingStates["mintNFTs" + nft.metadataURI]}
            disabled={!isConnected}
          />
          {!isConnected ? (
            <>
              <span>Please connect your wallet </span>
            </>
          ) : null}
        </div>

        <div className="metadata">
          {nftData?.document_details &&
            renderDetails("Document Details", nftData.document_details)}
          {nftData?.parties &&
            renderDetails("Parties Involved", nftData.parties)}
          {nftData?.property_details &&
            renderDetails("Property Details", nftData.property_details)}
          {nftData?.transaction &&
            renderDetails("Transaction", nftData.transaction)}
          {nftData?.taxes_and_fees &&
            renderDetails("Taxes and Fees", nftData.taxes_and_fees)}
        </div>
      </div>
    </div>
  );
};

export default NFTList;
