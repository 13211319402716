import "./MintNFT.scss";

import { useState } from "react";

import { Container, ContainerContent } from "../../mcn-react-ui";
import HeaderButtons from "../../mcn-react-ui/HeaderButtons/HeaderButtons";
import History from "./History/History";
import NFTList from "./NFTList/NFTList";

const MintNFT = () => {
  const [activeButton, setActiveButton] = useState("Mint");

  const buttons = [{ text: "Mint" }, { text: "History" }];
  return (
    <Container>
      <HeaderButtons
        activeButton={activeButton}
        setActiveButton={setActiveButton}
        buttons={buttons}
      />
      <ContainerContent className="tokenDistributionPage">
        {activeButton === "Mint" && (
          <NFTList setActiveButton={setActiveButton} />
        )}
        {activeButton === "History" && <History />}
      </ContainerContent>
    </Container>
  );
};

export default MintNFT;
