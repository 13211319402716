import "./ToolsBar.scss";

import { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";

import { Logo } from "../../assets/svg/svgIcons";
import useWindowWidth from "../../hooks/useWindowWidth";
import WalletConnectButton from "../../pages/MintNFT/WalletConnectButton/WalletConnectButton";

const ToolsBar = (): JSX.Element => {
  const { isMobile } = useWindowWidth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <span className="toolsBarPageDiv">
      <span className="content">
        <Logo />
        {!isMobile && (
          <span className="center">This is the NFT Contract Test 1</span>
        )}
        <span className="right">
          {isMobile ? (
            <button className="burgerButton" onClick={toggleMenu}>
              {isMenuOpen ? null : <FaBars />}
            </button>
          ) : (
            <WalletConnectButton />
          )}
        </span>
      </span>

      {/* Menu latéral mobile */}
      {isMobile && isMenuOpen && (
        <div className="mobileMenu">
          <button className="closeButton" onClick={toggleMenu}>
            <FaTimes />
          </button>
          <span onClick={toggleMenu} className="menu-item">
            This is the NFT Contract Test 1
          </span>
          <span className="menu-item">
            <WalletConnectButton />
          </span>
        </div>
      )}
    </span>
  );
};

export default ToolsBar;
